import { NestedKeyOf } from "../sharedTranslations/localize";

export type NestedPath<T extends object> = NestedKeyOf<DeepRequired<T>>;

export type DeepRequired<T> = {
  [K in keyof T]-?: T[K] extends object ? DeepRequired<T[K]> : T[K];
};

/**
 * Near-identical type (with some minor tweaks) pulled from @shopify/app-bridge
 * TODO: Update to allow objects as value if path is an intermediate object
 */
export type PathValue<T, Path extends string> = Path extends `${infer Key}.${infer Rest}`
  ? Key extends keyof T
    ? Rest extends string
      ? PathValue<NonNullable<T[Key]>, Rest>
      : never
    : never
  : Path extends keyof T
    ? T[Path] | undefined
    : never;

/**
 * Sets values of nested properties
 * Ex. modernThemeSettings.sections.banner.display
 */
export const setNestedProperty = <T extends object, P extends NestedPath<T>, V extends PathValue<T, P>>(
  obj: T,
  path: P,
  value: V
) => {
  const keys = path.split(".") as Array<keyof T>;
  let current: any = obj;

  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];
    if (!current[key]) {
      current[key] = {};
    }
    current = current[key];
  }

  current[keys[keys.length - 1]] = value;
  return obj;
};
